body {
  background-color: #f1f1f1;
}

.primary-btn {
  border: 2px solid red;
  color: red;
  padding: 6px 20px;
  border-radius: 3px;
}

.secondary-btn {
  border: 2px solid black;
  color: black;
  padding: 6px 20px;
  border-radius: 3px;
}

/* 
.question {
  font-size: 28px;
} */

.score {
  font-size: 75px;
  font-weight: bold;
}

.video-section .video-image {
  position: relative;
}

.video-image img {
  border-radius: 6px;
}

.video-image {
  position: relative;
  margin: auto;
  width: 90%;
}

@media screen and (min-width: 922px) {
  .video-image {
    width: 55%;
  }
}

@media screen and (min-width: 1400px) {
  .video-image {
    width: 40%;
  }
}

.video-image img {
  width: 100%;
  height: auto;
}

.video-image .icon-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  width: 75px;
  height: 75px;
  padding: 12px 24px;
  font-size: 27px;
  cursor: pointer;
  border-radius: 50%;
  line-height: 46px;
  border: 2px solid white;
}

.video-image .icon-circle:hover {
  background-color: #8f0c0c;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #3e98c7 !important;
}

.CircularProgressbar-path {
  stroke: #3e98c7 !important;
}

.CircularProgressbar-text {
  font-weight: bold;
}